import http from './http-common';

const API_URL = 'https://api.prxpo.store/api/';

// Eliminar la variable global userData no utilizada
// const userData = {};

const login = async (email, password) => {
  try {
    const response = await fetch(`${API_URL}users/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) throw new Error('Error en la solicitud de login');

    const data = await response.json();
    if (data.token) {
      localStorage.setItem('token', data.token); // Almacenar el token como string
    }
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// Eliminar la función getUserData no utilizada si no es necesaria
// Si es necesaria, asegúrate de usarla correctamente en otro lugar de tu aplicación
const getUserData = async () => {
  const token = localStorage.getItem('token'); // Obtener el token del localStorage
  try {
    const response = await fetch(`${API_URL}users/me`, { // Asegúrate de reemplazar 'api/users/me' con tu endpoint correcto
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // Incluir el token en el header de Authorization
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) throw new Error('Error al obtener los datos del usuario');
    const userData = await response.json();
    return userData;
  } catch (error) {
    console.error('Error al obtener los datos del usuario:', error);
  }
}

const register = async (email, password) => {
  try {
    const response = await http.post('/api/users/register', {
      email,
      password
      // Puedes agregar más campos aquí según lo requiera tu API
    });
    if (response.data.accessToken) {
      // Suponiendo que tu API devuelve un token de acceso en la respuesta
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

const authService = {
  login,
  register,
  getUserData, // Inclúyelo aquí si decides usarlo
};

export default authService;
