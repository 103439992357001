const API_URL = 'https://api.prxpo.store/api/';

const getStores = async () => {
  try {
    const token = localStorage.getItem('token'); // Obtener el token directamente como string
    console.log('Token:', token); // Verificar que el token se está obteniendo correctamente

    if (!token) {
      throw new Error('Token no disponible');
    }

    const response = await fetch(`${API_URL}stores/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener las tiendas');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error en getStores:', error);
    throw error;
  }
};

// Asigna el objeto a una variable antes de exportarlo
const storeServices = { getStores };

export default storeServices;
