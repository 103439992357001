import React, { useState } from 'react';
import './OrderDetailsModal.css';

const OrderDetailsModal = ({ order, onClose, onUpdateStatus }) => {
  const [newStatus, setNewStatus] = useState(order.state);

  const handleStatusChange = (e) => {
    setNewStatus(e.target.value);
  };

  const handleUpdateClick = () => {
    onUpdateStatus(order._id, newStatus);  // Llamada para actualizar el estado
    onClose();  // Cerrar el modal después de guardar
  };

  // Cerrar el modal si se hace clic fuera de él
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('order-modal-backdrop')) {
      onClose();
    }
  };

  return (
    <div className="order-modal-backdrop" onClick={handleBackdropClick}>
      <div className="order-modal-content">
        <h2 className="order-modal-header">Detalles del Pedido</h2>
        <table className="order-product-table">
          <thead>
            <tr>
              <th>Imagen</th>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Precio</th>
            </tr>
          </thead>
          <tbody>
            {order.products.map((product) => (
              <tr key={product.productId?._id}>
                <td>
                  <div className="order-thumbnail-circle">
                    {product.productId?.imageUrls?.thumbnail && (
                      <img
                        src={product.productId.imageUrls.thumbnail}
                        alt={product.productId.name}
                        className="order-thumbnail-image"
                      />
                    )}
                  </div>
                </td>
                <td>{product.productId?.name || 'Producto no disponible'}</td>
                <td>{product.quantity}</td>
                <td>${product.productId?.price || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="order-status-update">
          <label>Estado:</label>
          <select value={newStatus} onChange={handleStatusChange}>
            <option value="pending">Pendiente</option>
            <option value="shipped">Enviado</option>
            <option value="delivered">Entregado</option>
            <option value="cancelled">Cancelado</option>
          </select>
        </div>

        <div className="order-modal-buttons">
          <button onClick={handleUpdateClick}>Guardar</button>
          <button onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsModal;
