import authService from './AuthServices'; // Para obtener el token

const API_URL = process.env.REACT_APP_API_URL;

const getSellerOrders = async () => {
  try {
    const token = localStorage.getItem('token'); // Obtenemos el token del localStorage
    const response = await fetch(`${API_URL}/orders/`, {
      headers: {
        'Authorization': `Bearer ${token}`, // Autenticamos la solicitud
      },
    });
    
    if (!response.ok) {
      throw new Error('Error al obtener las órdenes');
    }

    const data = await response.json();
    return data.data; // Retornamos las órdenes del vendedor
  } catch (error) {
    console.error('Error en OrderService:', error);
    throw error;
  }
};

export default {
  getSellerOrders,
};
