import React, { useState, useEffect } from 'react';
import Header from '../components/Header/Header';
import StoreServices from '../services/StoreServices';
import './HomePage.css';
import { Link } from 'react-router-dom';
import CreateStoreModal from '../components/Store/CreateStoreModal';

const HomePage = () => {
  const [stores, setStores] = useState([]);
  const [error, setError] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isCreateStoreModalOpen, setIsCreateStoreModalOpen] = useState(false);

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  const toggleCreateStoreModal = () => {
    setIsCreateStoreModalOpen(!isCreateStoreModalOpen);
  };

  const fetchStores = async () => {
    try {
      const data = await StoreServices.getStores();
      setStores(data);
    } catch (error) {
      setError('Error al obtener las tiendas');
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const handleStoreCreated = async () => {
    await fetchStores(); // Vuelve a obtener las tiendas
  };

  return (
    <div className="home-container">
      <Header toggleLoginModal={toggleLoginModal} />
      <header></header>
      <h1 className='home-title'>Bienvenido a Prodexpo</h1>
      <h2 className='stores-title'>Tus tiendas Prodexpo:</h2>
      {error && <p>{error}</p>}
      <ul>
        {stores.map((store) => (
          <li key={store.id}>
            <Link to={`/${store.route}`}>
              {store.name}
            </Link>
          </li>
        ))}
          <li>
            <button onClick={toggleCreateStoreModal} className="create-store-button">Crear nueva tienda</button>
          </li>
      </ul>
      {isCreateStoreModalOpen && <CreateStoreModal onClose={toggleCreateStoreModal} onStoreCreated={handleStoreCreated} />}
    </div>
  );
};

export default HomePage;
