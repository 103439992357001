import React, { useState, useCallback } from 'react';
import { DndContext, closestCorners, useSensor, useSensors, MouseSensor, TouchSensor, DragOverlay } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import DraggableProductItem from './DraggableProductItem';

const DraggableProduct = ({ productos, setProductos, isOwner, openEditProductModal, openModal }) => {
    const [activeId, setActiveId] = useState(null);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor)
    );

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;

        if (over) {
            const visibleProducts = productos.filter(producto => !producto.isHidden);
            const oldIndex = visibleProducts.findIndex(item => item.slug === active.id);
            const newIndex = visibleProducts.findIndex(item => item.slug === over.id);

            if (oldIndex !== -1 && newIndex !== -1) {
                const newVisibleProducts = arrayMove(visibleProducts, oldIndex, newIndex);
                const newProducts = [
                    ...newVisibleProducts,
                    ...productos.filter(producto => producto.isHidden)
                ];
                setProductos(newProducts);
            }
        }

        setActiveId(null);
    }, [productos, setProductos]);

    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    const visibleProducts = productos.filter(producto => !producto.isHidden);
    const hiddenProducts = productos.filter(producto => producto.isHidden);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={visibleProducts.map(producto => producto.slug)} strategy={rectSortingStrategy}>
                <div className="gallery-container">
                    {visibleProducts.map((producto) => (
                        <DraggableProductItem
                            key={producto.slug}
                            producto={producto}
                            isOwner={isOwner}
                            openEditProductModal={openEditProductModal}
                            openModal={openModal}
                        />
                    ))}
                    {hiddenProducts.map((producto) => (
                        <DraggableProductItem
                            key={producto.slug}
                            producto={producto}
                            isOwner={isOwner}
                            openEditProductModal={openEditProductModal}
                            openModal={openModal}
                        />
                    ))}
                </div>
            </SortableContext>
            <DragOverlay adjustScale={false} style={{ transition: 'none', transformOrigin: '0 0 ' }}>
                {activeId && (
                    <DraggableProductItem
                        producto={productos.find(producto => producto.slug === activeId)}
                        isOwner={isOwner}
                        openEditProductModal={openEditProductModal}
                        openModal={openModal}
                    />
                )}
            </DragOverlay>
        </DndContext>
    );
};

export default DraggableProduct;
