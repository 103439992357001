import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (product) => {
    const existente = cart.find((item) => item._id === product._id);
    if (existente) {
      setCart(
        cart.map((item) =>
          item._id === product._id ? { ...item, amount: item.amount + 1 } : item
        )
      );
    } else {
      setCart([...cart, { ...product, amount: 1 }]);
    }
  };

  const removeFromCart = (productId) => {
    const existente = cart.find((item) => item._id === productId);
    if (existente.amount > 1) {
      setCart(
        cart.map((item) =>
          item._id === productId ? { ...item, amount: item.amount - 1 } : item
        )
      );
    } else {
      setCart(cart.filter((item) => item._id !== productId));
    }
  };

  const clearCart = () => {
    setCart([]); // Limpia todos los productos del carrito
  };

  const createOrder = async (rutaActual, userData = null) => {
    const token = localStorage.getItem('token'); // Verificamos si el usuario está logueado
    
    // Mapeamos los productos del carrito
    const orderProducts = cart.map(product => ({
      productId: product._id,  // Enviar solo el ID del producto
      quantity: product.amount
  }));
  
    // Definimos el subtotal
    const subTotal = cart.reduce((acc, product) => acc + product.amount * product.price, 0);

    // Estructura básica de la orden
    const orderData = {
      products: orderProducts,
      subTotal,
      route: rutaActual, // Incluimos la ruta de la tienda en la orden
    };
  
    // Si no hay token (usuario no autenticado), enviamos los datos del usuario proporcionados
    // Añadir datos del usuario si están disponibles
    if (!token && userData) {
      Object.assign(orderData, userData);
  }
  
    // Enviar la orden al backend
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/orders`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              ...(token && { 'Authorization': `Bearer ${token}` }),
          },
          body: JSON.stringify(orderData),
      });

      if (response.ok) {
          const result = await response.json();
          return result;
      } else {
          throw new Error('Error al crear la orden');
      }
  } catch (error) {
      console.error('Error en la solicitud de creación de orden:', error);
      throw error;
  }
  };  
  

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart, createOrder }}>
      {children}
    </CartContext.Provider>
  );
};