import React, { useState, useRef } from 'react';
import './AddProductModal.css';
import axios from 'axios';

const AddProductModal = ({ store, onClose, onProductAdded }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [stock, setStock] = useState('');
    const [image, setImage] = useState(null);
    const [isFeatured, setIsFeatured] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const fileInputRef = useRef(null);

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('price', price);
        formData.append('stock', stock);
        formData.append('isFeatured', isFeatured);
        formData.append('isHidden', isHidden);
        if (image) {
            formData.append('file', image);
        }

        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(`https://api.prxpo.store/api/products/create/${store}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log('Producto agregado:', response.data);
            onProductAdded(); // Llama a la función para actualizar la galería
            onClose();
        } catch (error) {
            console.error('Error al agregar el producto:', error);
        }
    };

    return (
        <div className="add-product-modal-backdrop" onClick={onClose}>
            <div className="add-product-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Agregar Producto</h2>
                <form onSubmit={handleSubmit} className="add-product-form">
                    <label>
                        Nombre:
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                    </label>
                    <label>
                        Descripción:
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
                    </label>
                    <table>
                        <tbody>
                            <tr>
                                <td><label>Precio:</label></td>
                                <td><input type="number" step="0.01" value={price} onChange={(e) => setPrice(e.target.value)} required /></td>
                                <td><label>Cantidad:</label></td>
                                <td><input type="number" value={stock} onChange={(e) => setStock(e.target.value)} required /></td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <div className="checkbox-container">
                                        <div className="checkbox-wrapper">
                                            <input
                                                type="checkbox"
                                                checked={isFeatured}
                                                onChange={(e) => setIsFeatured(e.target.checked)}
                                                className="checkbox-input"
                                            />
                                            <span>Producto destacado</span>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input
                                                type="checkbox"
                                                checked={isHidden}
                                                onChange={(e) => setIsHidden(e.target.checked)}
                                                className="checkbox-input"
                                            />
                                            <span>Ocultar producto</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Imagen:</label>
                                </td>
                                <td className="file-input-container">
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                    />
                                    <button type="button" onClick={handleFileButtonClick} className="choose-file-button">Elegir archivo</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="submit" className="submit-button">Agregar</button>
                </form>
                <button onClick={onClose} className="close-button-img-modal">Cerrar</button>
            </div>
        </div>
    );
};

export default AddProductModal;
