import React, { useState } from 'react';
import './CreateStoreModal.css';
import axios from 'axios';

const CreateStoreModal = ({ onClose, onStoreCreated }) => {
    const [name, setName] = useState('');
    const [route, setRoute] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://api.prxpo.store/api/stores', { name, route }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log('Tienda creada:', response.data);
            onStoreCreated(); // Llama a la función para actualizar la lista de tiendas
            onClose();
        } catch (error) {
            console.error('Error al crear la tienda:', error);
        }
    };

    return (
        <div className="create-store-modal-backdrop" onClick={onClose}>
            <div className="create-store-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Crear Tienda</h2>
                <form onSubmit={handleSubmit} className="create-store-form">
                    <label>
                        Nombre:
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                    </label>
                    <label>
                        Ruta:
                        <input type="text" value={route} onChange={(e) => setRoute(e.target.value)} required />
                    </label>
                    <button type="submit" className="submit-button">Crear</button>
                </form>
                <button onClick={onClose} className="close-button-img-modal">Cerrar</button>
            </div>
        </div>
    );
};

export default CreateStoreModal;
