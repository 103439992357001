import React, { useState, useEffect, useCallback } from 'react';
import DraggableProduct from '../components/Product/DraggableProduct'; // Importamos el nuevo DraggableProduct
import ProductModal from '../components/Product/ProductModal';
import AddProductModal from '../components/Product/AddProductModal';
import EditProductModal from '../components/Product/EditProductModal';
import './ProductGallery.css';

const ProductGallery = ({ store }) => {
  const [productos, setProductos] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  const fetchProducts = useCallback((isOwnerStatus) => {
    const url = `https://api.prxpo.store/api/stores/products/${store}`;

    fetch(url, {
      method: 'GET',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al obtener los productos');
        }
        return response.json();
      })
      .then(data => {
        const visibleProducts = data.filter(producto => !producto.isHidden || isOwnerStatus);
        setProductos(visibleProducts);
      })
      .catch(error => console.error("Error al cargar productos:", error));
  }, [store]);

  const checkIfOwner = async (store, token) => {
    try {
      const response = await fetch(`https://api.prxpo.store/api/stores/${store}/isOwner`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Error al verificar el propietario de la tienda');
      }
      const data = await response.json();
      return data.isOwner;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      checkIfOwner(store, token).then(isOwnerStatus => {
        setIsOwner(isOwnerStatus);
        fetchProducts(isOwnerStatus);
      }).catch(error => {
        console.error('Error al verificar el propietario de la tienda:', error);
      });
    } else {
      fetchProducts(false);
    }
  }, [store, fetchProducts]);

  const openModal = (producto) => {
    setSelectedImage(producto);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const openAddProductModal = () => {
    setIsAddProductModalOpen(true);
  };

  const closeAddProductModal = () => {
    setIsAddProductModalOpen(false);
  };

  const openEditProductModal = (producto) => {
    setProductToEdit(producto);
    setIsEditProductModalOpen(true);
  };

  const closeEditProductModal = () => {
    setProductToEdit(null);
    setIsEditProductModalOpen(false);
  };

  const handleDeleteProduct = async (slug) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token no encontrado');
      return;
    }

    try {
      const response = await fetch(`https://api.prxpo.store/api/products/delete/${slug}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Error al eliminar el producto');
      }

      fetchProducts(isOwner);
      closeModal();
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };

  return (
    <div className="gallery-background">
      {isOwner && (
        <div className="button-container">
          <button onClick={openAddProductModal} className="add-product-button">
            Agregar Producto
          </button>
        </div>
      )}
      <DraggableProduct
        productos={productos}
        setProductos={setProductos}
        isOwner={isOwner}
        openEditProductModal={openEditProductModal}
        openModal={openModal}
      />
      {selectedImage && (
        <ProductModal
          producto={selectedImage}
          onClose={closeModal}
          onEdit={openEditProductModal}
          onDelete={handleDeleteProduct}
          esDueno={isOwner}
        />
      )}
      {isAddProductModalOpen && (
        <AddProductModal
          store={store}
          onClose={closeAddProductModal}
          onProductAdded={() => fetchProducts(isOwner)}
        />
      )}
      {isEditProductModalOpen && (
        <EditProductModal
          producto={productToEdit}
          onClose={closeEditProductModal}
          onProductEdited={() => fetchProducts(isOwner)}
        />
      )}
    </div>
  );
};

export default ProductGallery;
