import React, { useContext } from 'react';
import { CartContext } from './CartContext';
import './CarritoModal.css';
import { useNavigate, useParams } from 'react-router-dom'; // Asegurarse de importar useNavigate

const CarritoModal = ({ estado, cerrarCarrito }) => {
  const { cart, removeFromCart, addToCart } = useContext(CartContext);
  const navigate = useNavigate(); // Inicializar useNavigate
  const { store } = useParams();

  const total = cart.reduce((acc, producto) => acc + producto.price * producto.amount, 0).toFixed(2);
  let claseModal;
  if (estado === "entrando") {
    claseModal = "crt-modal entrando";
  } else if (estado === "saliendo") {
    claseModal = "crt-modal saliendo";
  } else {
    claseModal = "crt-modal";
  }

  const handlePurchase = () => {

    // Redirigir a /{store}/checkout
    navigate(`/${store}/checkout`);
  };

  return (
    <div className="crt-modal-backdrop" onClick={cerrarCarrito}>
      <div className={claseModal} onClick={e => e.stopPropagation()}>
        <div className="crt-content">
          {cart.map((producto, index) => (
            <div key={index} className="crt-pdct">
            <div className="thumbnail-circle">
              <img src={producto.imageUrls.thumbnail} alt={producto.name} className="thumbnail-image" />
            </div>
              <span className='crt-pdct-name'>{producto.name}</span>
              <span className='crt-pdct-price'>${producto.price}</span>
              <div className='amount-handler'>
                <button className='remove-cart-item-btn' onClick={() => removeFromCart(producto._id)}>-</button>
                <span className='crt-pdct-amount'>{producto.amount}</span>
                <button className='add-cart-item-btn' onClick={() => addToCart(producto)}>+</button>
              </div>
            </div>
          ))}
          <div className="cart-total">
            <span>Total: ${total}</span>
          </div>
          <button className='purchase-btn' onClick={handlePurchase}>Comprar</button>
        </div>
        <button className='close-button' onClick={cerrarCarrito}>X</button>
      </div>
    </div>
  );
}

export default CarritoModal;
