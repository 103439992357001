import React, { useState, useContext, useEffect } from 'react';
import './Header.css';
import { CartContext } from '../Cart/CartContext';
import CarritoModal from '../Cart/CarritoModal';
import logo from '../../assets/prodexpo-logo.png';
import sclogo from '../../assets/shopping_cart.png';
import { useNavigate } from 'react-router-dom';

const Header = ({ toggleLoginModal }) => {
  const navigate = useNavigate();
  const { cart } = useContext(CartContext);
  const productAmount = cart.reduce((acc, producto) => acc + producto.amount, 0);

  const [estadoCarrito, setEstadoCarrito] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const abrirCarrito = () => {
    setEstadoCarrito("entrando");
    setTimeout(() => {
      setEstadoCarrito("abierto");
    }, 100);
  };

  const cerrarCarrito = () => {
    setEstadoCarrito("saliendo");
    setTimeout(() => {
      setEstadoCarrito(null);
    }, 100);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleLogoutClick = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    window.location.reload();
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleMyOrdersClick = () => {
    navigate('/mispedidos');
  };

  return (
    <div className='header'>
      {!isLoggedIn ? (
        <button className='login-btn' onClick={handleLoginClick}>Iniciar sesión</button>
      ) : (
        <>
          {isLoggedIn && <button className='logout-btn' onClick={handleLogoutClick}>Cerrar sesión</button>}
          <button className='my-orders-btn' onClick={handleMyOrdersClick}>Mis Pedidos</button>
        </>
      )}
      <img src={logo} alt="Logo" className='logo' onClick={handleLogoClick} />
      <button onClick={abrirCarrito} className='cart-btn'>
        <div className='cart-count'>{productAmount}</div>
        <img src={sclogo} alt='shopping cart icon'></img>
      </button>
      {estadoCarrito && <CarritoModal estado={estadoCarrito} cerrarCarrito={cerrarCarrito} />}
    </div>
  );
};

export default Header;
