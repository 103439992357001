import axios from 'axios';

const http = axios.create({
  baseURL: "https://api.prxpo.store/", // Sustituye esto con la URL base de tu API
  headers: {
    "Content-type": "application/json"
    // Aquí puedes añadir más headers globales si es necesario
  }
});

export default http;