import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useContext } from 'react';
import { CartContext } from '../Cart/CartContext';
import './ProductModal.css';

const ProductModal = ({ producto, onClose, onEdit, onDelete, esDueno: isOwner }) => {
  function crearEfectoparticles(e) {
    const cantidadparticles = 20;
    for (let i = 0; i < cantidadparticles; i++) {
      let particle = document.createElement('div');
      particle.style.left = `${e.clientX}px`;
      particle.style.top = `${e.clientY}px`;
      particle.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
      document.body.appendChild(particle);
      particle.className = 'particle';

      const animation = particle.animate([
        { transform: 'scale(3)', opacity: 1 },
        { transform: `scale(0) translate(${Math.random() * 200 - 100}px, ${Math.random() * 200 - 100}px)`, opacity: 0 }
      ], {
        duration: Math.random() * 500 + 300,
        easing: 'ease-out',
        iterations: 1
      });

      animation.onfinish = () => particle.remove();
    }
  }

  const { addToCart } = useContext(CartContext);

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {isOwner && (
          <div className="edit-icon-container-modal">
            <div className="edit-icon-modal" onClick={() => onEdit(producto)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </div>
          </div>
        )}
        <picture>
          <source srcSet={producto.imageUrls.desktop} media="(min-width: 1024px)" />
          <source srcSet={producto.imageUrls.tablet} media="(min-width: 768px)" />
          <source srcSet={producto.imageUrls.mobile} media="(max-width: 767px)" />
          <img src={producto.imageUrls.desktop} alt={producto.name} />
        </picture>
        <div className="product-details">
          <p className='product-title'><b>{producto.name}</b></p>
          <p className='product-price'><b>${producto.price}</b></p>
          <p className='product-description'>{producto.description}</p>
          <button onClick={onClose} className='close-button-img-modal'>X</button>
          <button className='cart-button' onClick={(e) => {
            addToCart(producto);
            e.target.classList.add('boton-rebote');
            setTimeout(() => e.target.classList.remove('boton-rebote'), 1000);
            crearEfectoparticles(e);
          }}>Agregar al Carrito</button>
          {isOwner && (
            <button className='delete-button' onClick={() => onDelete(producto._id)}>Borrar Producto</button>
          )}
          {/* <div className='tags-container'>
          {producto.tags.map((tag, index) => (
            <div key={index} className="tag">
              <p>{tag}</p>
            </div>
          ))}
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
