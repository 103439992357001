import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../components/Cart/CartContext'; // El contexto del carrito
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';  // Agregar el Header
import './CheckoutPage.css';

const CheckoutPage = () => {
    const { cart, createOrder } = useContext(CartContext);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para verificar si el usuario está logueado
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phoneNumber: '',
    });
    const location = useLocation();
    const navigate = useNavigate(); // Para redirigir a la página de login
    const [isEditing, setIsEditing] = useState(false); // Para controlar el modo edición
    const total = cart.reduce((acc, producto) => acc + producto.price * producto.amount, 0).toFixed(2);

    const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setUserData({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    country: data.country,
                    postalCode: data.postalCode,
                    phoneNumber: data.phoneNumber,
                });
            } catch (error) {
                console.error('Error al obtener datos del usuario:', error);
            }
        }
    };

    const updateUserProfile = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/update`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(userData),
                });
                if (response.ok) {
                    const updatedUser = await response.json();
                    setUserData(updatedUser);
                    setIsEditing(false); // Desactivamos el modo edición después de guardar
                }
            } catch (error) {
                console.error('Error al actualizar el perfil:', error);
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchUserData();
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    const handleSubmitOrder = async () => {
        const rutaActual = location.pathname.split('/')[1]; // Obtenemos la tienda
      
        try {
          // Crear la orden y obtener la respuesta completa
          const orderResponse = await createOrder(rutaActual, userData);
          
          // Redirigir a la página de thank you y pasar la respuesta de la orden como state
          navigate(`/${rutaActual}/thankyou`, { state: { order: orderResponse } });
        } catch (error) {
          console.error('Error al crear el pedido:', error);
        }
      };

    const handleLoginClick = () => {
        navigate('/login'); // Redirigir al login
    };

    return (
        <div className="checkout-page">
            <Header /> {/* Header agregado */}
            <div className="checkout-container">
                <div className="checkout-left">
                    <h2>Datos de Envío</h2>
                    {isLoggedIn ? (
                        <div>
                            {isEditing ? (
                                <form>
                                    <label>
                                        Nombre:
                                        <input
                                            type="text"
                                            value={userData.firstName}
                                            onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Apellido:
                                        <input
                                            type="text"
                                            value={userData.lastName}
                                            onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Dirección:
                                        <input
                                            type="text"
                                            value={userData.address}
                                            onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Ciudad:
                                        <input
                                            type="text"
                                            value={userData.city}
                                            onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Estado:
                                        <input
                                            type="text"
                                            value={userData.state}
                                            onChange={(e) => setUserData({ ...userData, state: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        País:
                                        <input
                                            type="text"
                                            value={userData.country}
                                            onChange={(e) => setUserData({ ...userData, country: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Código Postal:
                                        <input
                                            type="text"
                                            value={userData.postalCode}
                                            onChange={(e) => setUserData({ ...userData, postalCode: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Teléfono:
                                        <input
                                            type="text"
                                            value={userData.phoneNumber}
                                            onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
                                        />
                                    </label>
                                    <button type="button" onClick={updateUserProfile}>
                                        Guardar
                                    </button>
                                </form>
                            ) : (
                                <div>
                                    <p>
                                        <strong>Nombre:</strong> {userData.firstName}
                                    </p>
                                    <p>
                                        <strong>Apellido:</strong> {userData.lastName}
                                    </p>
                                    <p>
                                        <strong>Dirección:</strong> {userData.address}
                                    </p>
                                    <p>
                                        <strong>Ciudad:</strong> {userData.city}
                                    </p>
                                    <p>
                                        <strong>Estado:</strong> {userData.state}
                                    </p>
                                    <p>
                                        <strong>País:</strong> {userData.country}
                                    </p>
                                    <p>
                                        <strong>Código Postal:</strong> {userData.postalCode}
                                    </p>
                                    <p>
                                        <strong>Teléfono:</strong> {userData.phoneNumber}
                                    </p>
                                    <button onClick={() => setIsEditing(true)}>Editar</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <button className="checkout-login-btn" onClick={handleLoginClick}>
                                Iniciar Sesión
                            </button>
                            <h3>Continuar como invitado</h3>
                            <form>
                                <label>
                                    Nombre:
                                    <input
                                        type="text"
                                        value={userData.firstName}
                                        onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Apellido:
                                    <input
                                        type="text"
                                        value={userData.lastName}
                                        onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Dirección:
                                    <input
                                        type="text"
                                        value={userData.address}
                                        onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Ciudad:
                                    <input
                                        type="text"
                                        value={userData.city}
                                        onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Estado:
                                    <input
                                        type="text"
                                        value={userData.state}
                                        onChange={(e) => setUserData({ ...userData, state: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    País:
                                    <input
                                        type="text"
                                        value={userData.country}
                                        onChange={(e) => setUserData({ ...userData, country: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Código Postal:
                                    <input
                                        type="text"
                                        value={userData.postalCode}
                                        onChange={(e) => setUserData({ ...userData, postalCode: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Teléfono:
                                    <input
                                        type="text"
                                        value={userData.phoneNumber}
                                        onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
                                        required
                                    />
                                </label>
                            </form>
                        </div>
                    )}
                </div>

                <div className="checkout-right">
                    <h2>Tu Carrito</h2>
                    {cart.map((producto, index) => (
                        <div key={index} className="cart-item">
                        <div className="thumbnail-circle">
                          <img src={producto.imageUrls.thumbnail} alt={producto.name} className="thumbnail-image" />
                        </div>
                            <span>{producto.name}</span>
                            <span>Cantidad: {producto.amount}</span>
                            <span>Precio: ${producto.price}</span>
                        </div>
                    ))}
                    <div className="cart-total">
                        <strong>Total: ${total}</strong>
                    </div>
                </div>
            </div>

            {/* Botón de confirmar pedido fijo en la parte inferior derecha */}
            <button className="confirm-order-btn" onClick={handleSubmitOrder}>
                Confirmar Pedido
            </button>
        </div>
    );
};

export default CheckoutPage;
