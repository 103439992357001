import React from 'react';

function NotFoundPage() {
  return (
    <div>
        <header>
        </header>
        <h1 className='home-title'>404 not found mi broder</h1>
      {/* Aquí podemos agregar más contenido después */}
    </div>
  );
}

export default NotFoundPage;