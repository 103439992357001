import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faArrowsAlt } from '@fortawesome/free-solid-svg-icons';

const DraggableProductItem = ({ producto, isOwner, openEditProductModal, openModal }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: producto.slug,
        disabled: producto.isHidden // Deshabilitamos el arrastre si el producto está oculto
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0 : 1,
        cursor: isDragging ? 'grabbing' : 'grab',
        zIndex: isDragging ? 10 : 1,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`image-item ${producto.isHidden ? 'hidden-product' : ''}`}
            {...attributes} // Solo atributos generales, no listeners en el contenedor principal
        >
            {producto.isHidden && <div className="hidden-label">Oculto</div>}
            <img src={producto.imageUrls.thumbnail} alt={producto.name} onClick={() => openModal(producto)} />
            <p><b>{producto.name}</b></p>
            <p>${producto.price}</p>
            {isOwner && ( // Solo mostramos el botón de mover si no está oculto
                <>
                    {!producto.isHidden &&
                        <div className="move-icon-container" {...listeners}> {/* Aplicamos los listeners solo al botón */}
                            <FontAwesomeIcon icon={faArrowsAlt} className="move-icon" />
                        </div>}
                    <div className="edit-icon-container" onClick={() => openEditProductModal(producto)}>
                        <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" />
                    </div>
                </>
            )}
        </div>
    );
};

export default DraggableProductItem;
