import React, { useState, useEffect } from 'react';
import OrderDetailsModal from '../components/Orders/OrderDetailsModal';
import OrderService from '../services/OrderService'; 
import Header from '../components/Header/Header';  
import "./MyOrdersPage.css"

const MyOrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const statusMap = {
      pending: 'Pendiente',
      shipped: 'Enviado',
      delivered: 'Entregado',
      cancelled: 'Cancelado'
    };

    const fetchOrders = async () => {
        try {
          const orders = await OrderService.getSellerOrders(); // Llamamos al servicio para obtener las órdenes
          setOrders(orders);  // Guardamos las órdenes
        } catch (error) {
          console.error('Error al obtener las órdenes:', error);
        }
      };

    useEffect(() => {
      fetchOrders();
    }, []);

    const handleOrderClick = (orderId) => {
      const order = orders.find(o => o._id === orderId);
      setSelectedOrder(order);  // Abrir modal con el pedido seleccionado
    };

    const handleCloseModal = () => {
      setSelectedOrder(null);
      fetchOrders(); 
    };

    const handleUpdateStatus = async (orderId, newStatus) => {
        const token = localStorage.getItem('token');
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/orders/${orderId}/status`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,  // Autenticamos la solicitud
            },
            body: JSON.stringify({ status: newStatus }),  // Enviamos el nuevo estado
          });
          const data = await response.json();
          if (data.success) {
            setOrders(orders.map(o => (o._id === orderId ? { ...o, state: newStatus } : o)));  // Actualizamos el estado localmente
          }
        } catch (error) {
          console.error('Error al actualizar el estado del pedido:', error);
        }
      };

    return (
      <>
        <Header /> {/* Añadimos el header */}
        <div className="orders-page">
          <div className="orders-container">
            <h1 className="orders-header">Mis Pedidos</h1>
            <table className="orders-table">
              <thead>
                <tr>
                  <th>Número de Orden</th>
                  <th>Comprador</th>
                  <th>Cantidad de Productos</th>
                  <th>Total</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id} onClick={() => handleOrderClick(order._id)}>
                    <td className="order-number">{order.orderNumber}</td>
                    <td>{`${order.user.firstName} ${order.user.lastName}`}</td>
                    <td>{order.products.length}</td>
                    <td className="order-total">${order.subTotal}</td>
                    <td className="order-status">{statusMap[order.status]}</td> {/* Usamos el mapeo */}
                  </tr>
                ))}
              </tbody>
            </table>

            {selectedOrder && (
              <OrderDetailsModal 
                order={selectedOrder} 
                onClose={handleCloseModal} 
                onUpdateStatus={handleUpdateStatus} 
              />
            )}
          </div>
        </div>
      </>
    );
};

export default MyOrdersPage;
