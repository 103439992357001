import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header/Header';
import './ThankYouPage.css';

const ThankYouPage = () => {
  const location = useLocation();
  const { order } = location.state || {};
  
  const orderData = order?.data || {};

  if (!orderData.products || orderData.products.length === 0) {
    return <div>No se encontraron productos en la orden.</div>;
  }

  return (
    <div className="thankyou-page">
      <Header />
      <div className="thankyou-container">
        <h1>¡Muchas gracias por su compra!</h1>
        <h2>Datos de Envío</h2>
        <div className="shipping-details">
          <p><strong>Nombre:</strong> {orderData.user.firstName}</p>
          <p><strong>Apellido:</strong> {orderData.user.lastName}</p>
          <p><strong>Dirección:</strong> {orderData.user.address}</p>
        </div>

        <h2>Productos Comprados</h2>
        <div className="purchased-products">
          {orderData.products.map((product, index) => (
            <div key={index} className="purchased-product">
            <div className="thumbnail-circle">
              <img src={product.productId.imageUrls.thumbnail} alt={product.productId.name} className="thumbnail-image" />
            </div>
              <span><strong>Producto:</strong> {product.productId.name}</span>  {/* Los datos vienen del populate */}
              <span><strong>Cantidad:</strong> {product.quantity}</span>
              <span><strong>Precio:</strong> ${product.productId.price}</span>
            </div>
          ))}
        </div>

        <div className="total-amount">
          <h3><strong>Total Pagado:</strong> ${orderData.subTotal}</h3>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
