// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import Header from './components/Header/Header';
import LoginPage from './components/Login/LoginPage';
import ProductGallery from './pages/ProductGallery';
import CheckoutPage from './pages/CheckoutPage'; 
import ThankYouPage from './pages/ThankYouPage'; 
import MyOrdersPage from './pages/MyOrdersPage';
import { CartProvider } from './components/Cart/CartContext';
import { Routes, Route, useParams, Navigate, useNavigate } from 'react-router-dom';

function UserBasedContent() {
  const { store } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  useEffect(() => {
    const verificarTienda = async () => {
      try {
        const response = await fetch(`https://api.prxpo.store/api/stores/${store}`);
        setLoading(false);
        if (!response.ok) {
          navigate('/NotFoundPage');
        }
      } catch (error) {
        console.error('Error al verificar la tienda:', error);
        setLoading(false);
        navigate('/NotFoundPage');
      }
    };

    verificarTienda();
  }, [store, navigate]);

  if (loading) {
    return <div>Cargando...</div>; // Mostrar el mensaje de carga mientras se verifica la tienda
  }

  return (
    <>
      <Header toggleLoginModal={toggleLoginModal} />
      {isLoginModalOpen &&
        <div className='login-backdrop' onClick={() => setIsLoginModalOpen(false)}>
          <LoginPage onClose={toggleLoginModal} />
        </div>
      }
      <ProductGallery store={store} />
    </>
  );
}

function App() {
  console.log("App component mounted");
  return (
    <CartProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/NotFoundPage" element={<NotFoundPage />} />
            <Route path="/:store" element={<UserBasedContent />} />
            <Route path="/:store/checkout" element={<CheckoutPage />} />
            <Route path="/:store/thankyou" element={<ThankYouPage />} />
            <Route path="/mispedidos" element={<MyOrdersPage />} />
            <Route path="*" element={<Navigate replace to="/NotFoundPage" />} />
          </Routes>
        </div>
    </CartProvider>
  );
}

export default App;
